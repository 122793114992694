@import "~@/erp/styles/variables/variables.scss";
























































































































































.row {
  display: flex;
  .deliveryTime {
    display: flex;
    flex-direction: column;
  }
}
