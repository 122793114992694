@import "~@/erp/styles/variables/variables.scss";
































































































































































































::v-deep .el-table {
  td .cell {
    padding: 10px;
    overflow: visible;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
.total {
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}
