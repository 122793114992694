@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































































































































































































































































































































.handle-box {
  padding: 10px;
  .btn-send {
    border: none;
    outline: none;
    padding: 6px 10px;
    color: #fff;
    background: #1790ff;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.4s;
  }

  .btn-send:hover {
    opacity: 0.8;
  }

  .check-images {
    margin-left: 10px;
  }
}

.waiBox {
  width: 700px;
  margin: 0 auto;
  color: rgba(0,0,0,.89);
}
.headBox{
  display: flex;
  line-height: 0;
  .imgBox {
    flex: 1;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .txtBox {
    width: 286px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.txtBoxLine{
  font-size: 12px;
  display: flex;
  line-height: 14px;
  margin-bottom: 6px;
  span{
    flex-shrink: 0;
    width: 100px;
    text-align: left;
    // font-weight: bold;
    line-height: 1;
  }
}
.shipBillPanle{
  display: flex;
  margin-top: 20px;
  .shipPanleCont{
    margin-bottom: 20px;
    p{
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }
  h3{
    font-weight: normal;
    color: rgba(0,0,0,.89);
    font-size: 14px;
    margin-bottom: 10px;
  }
  .shipPanle{
    flex: 1;
    h3{
      font-weight: 600;
    }
  }
  .billPanle{
    width: 286px;
    margin-left: 40px;
    h3{
      font-weight: 600;
    }
  }
}


.clearfix:before,
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}
.pTL {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}

/* SHIP TO */
.shipto {
  margin-bottom: 20px;
}

.contBox {
  width: 377px;
  border: 1px solid #000000;
  line-height: 70px;
  float: left;
}

.contBox>div {
  border-bottom: 1px solid #000;
  padding: 5px;
}

.contBox>div:last-child {
  border-bottom: none;
}

.contBox p {
  line-height: 16px;
  font-size: 10px;
}

.cust {
  width: 264px;
  height: 118px;
  border: 1px solid #000000;
  background-color: #ffff66;
  margin-left: 54px;
  float: left;
}

.cust p {
  font-size: 13px;
  line-height: 18px;
}

.cust p i {
  font-size: 10px;
  font-weight: normal;
  background-color: #ffff99;
  border-bottom: 1px solid #000;
}

.cust div {
  font-size: 13px;
  line-height: 19px;

  position: relative;
  top: 6px;
}

.cust div em {
  width: 227px;
  display: inline-block;
  background-color: hsla(30, 79%, 63%, 0.678);
  font-size: 10px;
  font-weight: normal;
  border-bottom: 1px solid #000;
  position: absolute;
  right: 0px;
  top: 0px;
}

.cust p span {
  display: inline-block;
}

/* 表格 */
#tableExcel {
  border-collapse: collapse;
}

#tableExcel tr td {
  height: 20px;
}

#tableExcel tbody tr td {
  font-size: 10px;
  text-align: center;
}

#tableExcel tfoot tr td {
  text-align: center;
}

.info-panel{
  display: flex;
  margin-top: 30px;
  .ftTxt{
    flex: 1;
    .txtBoxLine{
      margin-bottom: 8px;
      span{
        width: 140px;
      }
    }
  }
  .ftName{
    width: 286px;
    height: 62px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .txtBoxLine{
      margin-bottom: 8px;
      span{
        width: 100px;
      }
      i{
        text-decoration: underline;
      }
    }
  }
}

.tbxia {
  font-size: 12px;
  text-align: right;
  margin-top: 10px;
  p{
    display: flex;
    justify-content: flex-end;
    .tbxia-title{
      text-align: right;
    }
    .tbxia-cont{
      min-width: 80px;
      text-align: right;
    }
  }
}

.tbxia i {
  font-weight: normal;
  margin-left: 10px;
}

/* 左右列表 */
.lrlist {
  margin-top: 10px;
  display: flex;
  color: rgba(0,0,0,.6);
  font-size: 12px;
}

.llist {
  flex: 1;
  padding-top: 10px;
  span{
    color: rgba(0,0,0,.89);
    font-weight: bold;
  }
}

.lblue {
  font-weight: bold;
  color: blue !important;
  font-size: 14px;
  .c_pointer{
    color: blue !important;
  }
  &.emText{
    font-style: italic;
  }
}

.rlist {
  width: 286px;
  margin-left: 40px;
  padding-top: 10px;
  border-top: 1px solid #e8e8e8;
  h3{
    color: rgba(0,0,0,.89);
  }
}
.mb8{
  line-height: 1;
  margin-bottom: 8px;
}

/* REMARK */
.ftbb {
  width: 100%;
  min-height: 100px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 12px;
  box-sizing: border-box;
  padding: 8px;
}

.lastDiv p {
  font-size: 12px;
  text-align: left;
}

.lastDiv a {
  font-size: 12px;
  color: blue !important;

  text-align: left;
}

input {
  padding-left: 5px;
}

.text-warp {
  // white-space: pre-wrap;
  white-space: pre-line;
  text-align: left;
  word-break: break-word;
}
.tableBox{
  #tableExcel{
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    thead{
      color: rgba(0,0,0,.6);
      font-size: 12px;
      background: #f5f7fa !important;
    }
    tr{
      border: none;
      text-align: left;
      th{
        border: none;
        text-align: left;
        padding:4px;
        font-weight: normal;
      }
      td{
        border: none;
        font-size: 13px;
        text-align: left;
        padding: 4px;
        vertical-align: top;
      }
    }
  }
  .fc-gray{
    color: rgba(0,0,0,.6);
  }
  .text-tr{
    text-align: right !important;
  }
  .pl20{
    padding-left: 20px;
  }
}
