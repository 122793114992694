@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































.more {
  color: #a0a0a0;
}
