@import "~@/erp/styles/variables/variables.scss";









































































.el-dialog__wrapper {
  /deep/ .el-dialog__header {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-size: 18px;
    color: #303133;
    .el-icon-warning {
      margin-right: 10px;
      color: #ffba00;
      font-size: 24px;
    }
  }
}
