@import "~@/erp/styles/variables/variables.scss";






































































































.el-form {
  display: flex;
  span {
    color: red;
    margin-right: 5px;
  }
  .el-form-item {
    flex: 1;
  }
}
